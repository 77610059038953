$(document).ready(function() {
	$('.tabs a').on('click', function(e) {
		let currentAttrValue = $(this).attr('href');

		// Show/Hide Tabs
		$('.tabs ' + currentAttrValue).siblings().slideUp(300);
        $('.tabs ' + currentAttrValue).delay(300).slideDown(300);

		// Change/remove current tab to active
		$(this).addClass('active').siblings().removeClass('active');

		e.preventDefault();
	});
});