let teamSlider = new Swiper ('.about__team-container', {
    slidesPerView: 'auto',
    spaceBetween: 15,
    mousewheel: true,
    wrapperClass: 'about__team-wrapper',
    slideClass: 'about__team-member',

    pagination: {
        el: '.about__team-nav__dots',
        dynamicBullets: false,
        clickable: true,
    },

    navigation: {
        prevEl: '.about__team-nav__button--prev',
        nextEl: '.about__team-nav__button--next',
    },
});