$(document).ready(function(){
    var counter = 0;
    $('.lang__item').click(function(){
        var parent = $(this).closest('.lang');
        var nextText = $(this).find('.lang-text').text();
        var curText = $('.lang__item').eq(0).find('.lang-text').text();

        parent.toggleClass('active');

        if(nextText == curText) return;
        
        $('.lang').attr('data-lang',nextText.toLowerCase())
        $(this).find('.lang-text').text(curText);
        $('.lang__item').eq(0).find('.lang-text').text(nextText);
    });

    $('.header__menu').click(function(){
        $('body').addClass('active');
        $('.mobile-menu').addClass('active');
    })
    $('.icon-close').click(function(){
        $('body').removeClass('active');
        $('.mobile-menu').removeClass('active');
    })
})